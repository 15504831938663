.features {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	padding: 40px 20px;
	background-color: #ffffff;
}
.feature {
	width: 300px;
	padding: 20px;
	text-align: center;
	background: #eaecee;
}
.feature img {
	width: 300px;
	height: auto;
	object-fit: cover;
	border-radius: 10px;
	margin-bottom: 20px;
}
.feature-prompt {
	color: #6c6f72;
	font-weight: bold;
}
