
.testimonials {
  padding: 40px;
  background-color: #ffffff;
}

.testimonial-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.testimonial {
  flex: 1;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 2px solid #eaecee;
}
.testimonial h3 {
  color: #4a4a4a;
  margin-bottom: 10px;
}
.testimonial p {
  color: #666;
  font-style: italic;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .testimonial-container {
      flex-direction: column;
  }
}
