/* App.css */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
}

h1 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
}

h2 {
  color: #3498db;
  text-align: center;
  font-size: 2em;
  margin-top: 40px;
  margin-bottom: 30px;
}

h3 {
  color: #e74c3c;
  font-size: 1.5em;
}

.intro {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 30px;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #f8f9fa;
}
footer {
  background-color: #4a4a4a;
  color: #ffffff;
  text-align: center;
  padding: 20px;
}
