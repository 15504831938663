header {
	background-color: #ffffff;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
	padding: 10px 20px;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	align-items: center;
}

header img {
	height: 40px; /* Adjust this value based on your logo's dimensions */
	width: auto;
	margin-right: 10px;
}

header .logo {
	font-size: 24px;
	font-weight: bold;
	color: #333; /* Adjust color to match your brand */
}
