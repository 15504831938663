.key-features {
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 20px;
}
.feature-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}
.feature-item {
  background-color: #eaecee;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
.feature-item:hover {
  transform: translateY(-5px);
}
.feature-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  color: #6e8efb;
}
.feature-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}
.feature-description {
  font-size: 1rem;
  color: #666;
}
@media (min-width: 640px) {
  .feature-list {
      grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .feature-list {
      grid-template-columns: repeat(3, 1fr);
  }
}
